ul.LangSwitch {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
}

ul.LangSwitch li {
    margin: 0;
    padding: 0 .25em;
    list-style: none;

}

ul.LangSwitch li:hover {
    text-decoration: underline;
    cursor: pointer;
}
