.CookieNote {
  box-sizing: border-box;
  position: absolute;
  left: 5px;
  bottom: 5px;
  padding: 5px;
  width: calc(100% - 10px);
  border: 3px solid #164370;
  color: #fff;
  background-color: rgba(22, 67, 112, .75);
  transform: translateY(115%);
  transition: 1s ease;
  animation: slide-in .7s 1s ease forwards;

  h1 {
    margin: 0;
    font-size: .9rem;
  }

  p {
    margin: 5px 0;
    font-size: .75rem;
  }

  a {
    color: #FFF;
  }

  button {
    width: 100%;
    height: 30px;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 5px;
    color: #fff;
  }

  button:hover {
    background-color: rgba(255, 255, 255, .2);
  }
}

.hide {
  animation: slide-in ease forwards, slide-out .7s ease forwards;
}

@keyframes slide-in {
  0% { transform: translateY(115%) }
  100% { transform: translateY(0) }
}

@keyframes slide-out {
  0% { transform: translateY(0) }
  100% { transform: translateY(115%) }
}

@media all and (min-width: 576px) {
  .CookieNote {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: calc(100% - 10px);

    button {
      width: auto;
      margin-left: 10px;
    }
  }
}

