@import "../../../vars";

.Paragraph {
  .ParagraphTitle {
    margin: 2em 0 1em 0;
    padding-top: 1rem;
    padding-bottom: 0.625rem;
    font-size: 1.5rem;
    color: $ci-blue;
    border-bottom: 1px solid $ci-blue;
  }

  h3,
  h4 {
    margin-top: 2.4em;
    font-size: 1.125rem;
    line-height: 1.4em;
    font-style: italic;
    color: $ci-blue;

    @media screen and (min-width: 768px) {
      font-size: 1.5rem;
    }
  }

  p,
  li {
    font-size: .9rem;
    line-height: 1.5;
    font-weight: 400;
  }

  p {
    margin: 1.5em 0;

    &.reference {
      font-size: .75rem;
      margin: .75em 0;
    }
  }

  ol,
  ul {
    position: relative;
    margin: 1.5em 0;
    padding: 0;
    list-style-type: none;

    ol, ul {
      margin: .25em 0 0;
    }

    li {
      position: relative;
      padding-left: 1.75em;
      margin-bottom: .25em;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  ol {
    counter-reset: section;

    li {
      &::before {
        counter-increment: section;
        content: counters(section, ".") ".";
      }
    }
  }

  ul > li:before {
    content:"–";
  }

  a,
  a:visited,
  a:focus {
    color: $ci-blue;
    word-break: break-all;
  }

  a.more {
    padding-left: 1em;
    display: inline-block;
    position: relative;
  }

  a.more:before {
    content:">";
    position: absolute;
    left: 0;
    top: 0;
  }


  .info-box {
    margin: 2rem -2rem;
    padding: 2rem 2rem;
    background-color: $accordion-item-infobox-color;
    color: $ci-blue;

    & *:first-child {
      margin-top: 0 !important;
    }

    & *:last-child {
      margin-bottom: 0 !important;
    }

    p, li {
      font-size: 1rem;
      color: $ci-blue;
    }
  }

  a sup {
    display:inline-block;
    border-bottom:1px solid #164370;
    padding-bottom:1px;
  }
}