.Page {
  max-width: 748px;
  position: relative;
  margin: auto;
}

.PageOpen .Page {
  margin-bottom: 3rem;
}

.Page h1 {
  position: absolute;
  top: -96px;
  left: 0;
  margin: 0;
  transform: translateY(-100%);
  color: #fff;
  z-index: 3;
}

@media screen and (min-width: 768px) {
  .Page h1 {
    top: -128px;
  }
}

@media screen and (max-width: 912px) {
  .Page {
    padding-right: 0;
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

