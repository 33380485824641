.Menu {
  font-size: .75rem;
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  margin: 0 -.5em;

  li {
    list-style: none;

    a {
      display: block;
      font-size: 1em;
      line-height: 2rem;
      padding: 0 .5em;
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
