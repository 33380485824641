@import "../../../../../vars";

.NavigationItem {
    display: block;
    text-align: right;
    margin-bottom: .75rem;
}
.NavigationItem:last-child {
    margin: 3vh 0;
}
.NavigationItem.MenuItem {
    a span {
        text-transform: uppercase;
    }
}
.NavigationItem a {
    color: #FFF;
    line-height: 1.5em;
    hyphens: auto;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
    color: $ci-green;
    opacity: .8;
}
