* {
  box-sizing: border-box;
}
*:after, *:before {
  box-sizing: border-box;
}

/* Light */
@font-face {
  font-family: "Orgon";
  font-weight: 300;
  font-style: normal;
  src: url("/fonts/5031525/33650825-d8ec-4ade-bc1a-41d4071435db.eot?#iefix");
  src: url("/fonts/5031525/33650825-d8ec-4ade-bc1a-41d4071435db.eot?#iefix") format("eot"),
  url("/fonts/5031525/19a7d1de-86d1-49a4-960a-90abdbab4b14.woff2") format("woff2"),
  url("/fonts/5031525/0c8eb526-4695-4501-8f61-befae7414471.woff") format("woff"),
  url("/fonts/5031525/0b1a8e8b-891b-47f4-9671-930f698a4cca.ttf") format("truetype");
}
@font-face {
  font-family: "Orgon";
  font-weight: 300;
  font-style: italic;
  src: url("/fonts/5031609/64248352-e6db-4baa-ab42-47a4b605aeed.eot?#iefix");
  src: url("/fonts/5031609/64248352-e6db-4baa-ab42-47a4b605aeed.eot?#iefix") format("eot"),
  url("/fonts/5031609/f62dab60-84ff-428c-a8b7-af5512d794ce.woff2") format("woff2"),
  url("/fonts/5031609/128121ef-a575-4498-b70f-bdfabeee82bc.woff") format("woff"),
  url("/fonts/5031609/494f12ac-44bb-42cf-a800-114c7af3b441.ttf") format("truetype");
}


/* Regular */
@font-face {
  font-family: "Orgon";
  font-weight: 400;
  font-style: normal;
  src: url("/fonts/5031651/ada68cf6-7df5-4309-b35e-cc8cb861e13d.eot?#iefix");
  src: url("/fonts/5031651/ada68cf6-7df5-4309-b35e-cc8cb861e13d.eot?#iefix") format("eot"),
  url("/fonts/5031651/b28be3d5-856a-4040-8bee-ebf371e97694.woff2") format("woff2"),
  url("/fonts/5031651/f043b41b-5570-4114-9b92-69eaf6309db0.woff") format("woff"),
  url("/fonts/5031651/26b4a98a-c552-410f-aae6-2f9ad0eeb759.ttf") format("truetype");
}
@font-face {
  font-family: "Orgon";
  font-weight: 400;
  font-style: italic;
  src: url("/fonts/5031635/201cd7c0-b7b0-440e-9796-4964c9b894b1.eot?#iefix");
  src: url("/fonts/5031635/201cd7c0-b7b0-440e-9796-4964c9b894b1.eot?#iefix") format("eot"),
  url("/fonts/5031635/3e05f69e-cfbd-4f7a-9f81-760ce8b9cd59.woff2") format("woff2"),
  url("/fonts/5031635/6d92383d-19c9-40ba-80fe-f8f6ceac03a9.woff") format("woff"),
  url("/fonts/5031635/7eb18184-15bc-4081-a857-6fcf8f4a18c6.ttf") format("truetype");
}


/* Medium */
@font-face {
  font-family: "Orgon";
  font-weight: 700;
  font-style: normal;
  src: url("/fonts/5031667/c8967afe-219f-4a16-888e-eead9f17b5f0.eot?#iefix");
  src: url("/fonts/5031667/c8967afe-219f-4a16-888e-eead9f17b5f0.eot?#iefix") format("eot"),
  url("/fonts/5031667/b5bf4e8f-7962-4f3c-b9b8-b2f2deee0238.woff2") format("woff2"),
  url("/fonts/5031667/e2fc4a91-8b93-4fac-a528-198f39565254.woff") format("woff"),
  url("/fonts/5031667/aca7be52-dc4a-4c82-9f03-04b63dcb017b.ttf") format("truetype");
}
@font-face {
  font-family: "Orgon";
  font-weight: 700;
  font-style: italic;
  src: url("/fonts/5031691/922a27cb-2afe-4e61-b9a7-82cdc7d00fa6.eot?#iefix");
  src: url("/fonts/5031691/922a27cb-2afe-4e61-b9a7-82cdc7d00fa6.eot?#iefix") format("eot"),
  url("/fonts/5031691/9891fd21-b3ee-494a-82d2-5fdf4cf055fe.woff2") format("woff2"),
  url("/fonts/5031691/c69ccf53-a88d-4ebf-ab57-5a50b5b293ab.woff") format("woff"),
  url("/fonts/5031691/612c36b1-4ac6-40bb-b59b-a06cef6cb2bc.ttf") format("truetype");
}

html {
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
  padding-top: 70px;
  font-family: Orgon, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (min-width: 768px) {
  body {
    padding-top: 0;
  }
}
  /*STYLING FONTS GENERAL*/
h1 {
    font-size: 1.375em;
}


h2 {
    font-weight: 300;
}

h2.page-title span {

}

h3 {

}

p {
    font-size: .9rem;
    line-height: 1.5;
    font-weight: 400;
    hyphens: auto;
}

li a {
    font-size: 1.25em;
}

sup {
  line-height: 1em;
}

@media screen and (min-width: 768px) {
  h1 {
    font-size: 2.5rem;
  }
}
