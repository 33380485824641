
$ci-blue: #164370;
$ci-green: #BBC925;
$ci-white: #FFFFFF;
$ci-grey: #CCCCCC;
$ci-skyblue: #E5EBEF;

$accordion-item-header-color: #BBC925;
$accordion-item-body-color: #FFFFFF;
$accordion-item-link-color: #BBC925;
$accordion-item-button-color: #BBC925;
$accordion-item-button-text-color: #FFFFFF;

$accordion-item-header-color-inv: #FFFFFF;
$accordion-item-body-color-inv: #164370;
$accordion-item-link-color-inv: #FFFFFF;
$accordion-item-button-color-inv: #164370;
$accordion-item-button-text-color-inv: #FFFFFF;

$accordion-item-infobox-color: #E5EBEF;
$color-text: #000;


@mixin close_icon() {
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center scroll;
  background-size: contain;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Ctitle%3Eclose%3C/title%3E%3Cpath d='M3.636 2.224l14.142 14.142-1.414 1.414L2.222 3.638z' fill='%23BBC925'/%3E%3Cpath d='M17.776 3.636L3.634 17.778 2.22 16.364 16.362 2.222z' fill='%23BBC925'/%3E%3C/svg%3E");
}
