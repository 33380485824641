.Accordion {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
  overflow: auto;
  margin: 0;
  padding: 0;
  height: calc(100vh - 70px);
  transition: height .3s ease-in-out;
}

.App.PageOpen .Accordion {
  height: calc(50vh - 70px);
  min-height: 220px;

  @media (min-width: 768px) {
    height: 50vh;
  }
}

.App.PageOpen .Accordion::after {
  position: absolute;
  top: 0;
  right: 0;
  content: none;
  height: 100%;
  width: 180px;
  background-color: rgba(255, 255, 255, .3);
}

@media screen and (min-width: 768px) {
  .Accordion {
    height: 100vh;
    flex-direction: row;
  }

  .App.PageOpen .Accordion::after {
    content: ' ';
  }
}

@media screen and (max-width: 1068px) {
  .App.PageOpen .Accordion::after {
    width: 160px;
  }
}
