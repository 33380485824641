.SideDrawer {
    width: 100%;
    position: relative;
    z-index: 10;
    box-sizing: border-box;
    font-size: 1.125em;
    transition: transform 0.3s ease-out;
}

.SideDrawer.Open {
    transform: translateY(0);
    overflow: auto;
}

.SideDrawer.Close {
    transform: translateY(-200vh);
    position: absolute;
}

.Logo {
    height: 11%;
    margin-bottom: 32px;
}


@media screen and (min-width:768px) {
    .SideDrawer {
        min-width: 120px;
        font-size: 1rem;

    }
    .SideDrawer.Open {
        transform: translateX(0);
    }

    .SideDrawer.Close {
        position: relative;
        transform: translateX(-180px);
    }
}
