@import "../../vars";

.Auth {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    margin-top: -70px;
    overflow: hidden;
}

.LoginWrapper {
    position: relative;
}

@media screen and (min-width: 768px) {
    .Auth {
        flex-direction: row;
        margin-top: 0;
    }
}

.Auth > * {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Auth > .Salutation {
    flex: 1 1 50%;
    padding: 0 7.5%;
}

.Auth > .LoginWrapper {
    flex: 1 1 50%;
}

@media screen and (min-width: 768px) {
    .Auth > .Salutation {
        flex: 1 1 50%;
    }

    .Auth > .LoginWrapper {
        flex: 1 1 50%;
    }
}

.Salutation {
    background-color: $ci-blue;
    color: #FFF;
}


.Salutation img {
    width: 178px;
    height: auto;
    transform: translateY(-1rem);
    margin-left: -12px;
}


@media screen and (min-width: 768px) {
    .Salutation img {
        width: 210px;
        transform: translateY(-4rem);
        margin-left: -14px;
    }
}

.Salutation H1 {
    font-weight: 300;
    text-transform: uppercase;
    color:  $ci-green;
    font-size: 1.25rem;
}

.Salutation P {
    font-weight: 300;
    font-size: .75rem;
    line-height: 1.5em;
}

.LoginWrapper iframe {
    margin: 0 auto;
}

.LoginFooter {
    text-align: center;
}

.LoginFooter a {
    display: inline-block;
    color: $ci-blue;
    padding: 0 1em;
    font-weight: 300;
    font-size: .75rem;
    line-height: 1.5em;
}
