@import "../../vars";

.Toolbar {
    width: 100%;
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    color: #FFF;
    display: flex;
    flex-direction: row;
    padding: 21.5px;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    background-color: $ci-white;
    z-index: 90;
    transition: all 0.3s ease-in-out;
}

.Toolbar.Open {
    background-color: $ci-blue;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    /*left: 40px;*/
    /*width: calc(100vw - 80px);*/
    /*height: calc(100vh - 10px);*/
    width: 100vw;
    height: 100vh;
    padding: 27px;
}

.Logo {
    height: 80%;
}

@media (max-width: 767px) {
    .DesktopOnly {
        display: none;
    }
}

@media screen and (min-width:768px) {
    .Toolbar {
        width: 85px;
        height: 100vh;
        flex-direction: column;
        padding: 60px 27px 50px;
        background-color: $ci-blue;
        /*padding: 21.5px 27px;*/

    }

    .Toolbar.Open {
        width: 210px;
        padding: 60px 50px 50px 40px;
    }
}

