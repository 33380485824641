

.App {
  font-family: Orgon, sans-serif;
}

.display-hidden {
  display: none;
}

.App-header {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #282c34;
  min-height: 100vh;
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

