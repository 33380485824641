@import "../../vars";

.Welcome {
    position: absolute;
    height: calc(100vh - 70px - 4 * 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    overflow: hidden;
    min-height: 1px;
    z-index: 1;

    .wrapper {
        padding: 0 2rem;
        max-height: 100%;
        width: 100%;
        overflow: auto;
        flex: 0 0 auto;
    }
}

.PageOpen .Welcome {
    display: none;
}

.Welcome-title{
    color: $ci-green;
    margin: 1rem 0;
    font-size: 1.125rem;
}

.Welcome-body {
    margin: 0;
}

@media screen and (min-width: 768px) {
    .Welcome {
        width: calc(100vw - 85px - 4 * 110px);
        max-width: 500px;
        height: 100vh;
    }
}
