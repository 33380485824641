@import "../../../../vars";

.Copyright {
  text-align: right;
  color: $ci-skyblue;
  margin-top: 2rem;
}

.Copyright p {
  margin: 0;
}
