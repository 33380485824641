.SiteLogo {
  width: 100%;
  height: 27px;
}

.SiteLogo img {
  height: 100%;
  width: auto;
  opacity: 0;
  transition: all .3s ease;
}

.Toolbar.Open .SiteLogo {
  display: none;
}

.Toolbar.Open .SiteLogo img.Large {
  opacity: 0;
}
.Toolbar.Closed .SiteLogo img.Large {
  display: none;
}

.Toolbar.Closed .SiteLogo img.Small {
  opacity: 1;
}

@media (min-width:768px) {
  .SiteLogo {
    display: inline-block;
    position: absolute;
    top: 24px;
    left: 0;
  }

  .SiteLogo img {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  .Toolbar.Open .SiteLogo {
    display: block;
  }

  .Toolbar.Open .SiteLogo img.Large {
    opacity: 1;
  }

  .Toolbar.Closed .SiteLogo img.Large {
    display: inline-block;
  }

  .Toolbar.Closed .SiteLogo img.Small {
    opacity: 1;
  }

}
