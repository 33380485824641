@import "../../../vars";

.JumpNav {
  position: fixed;
  top: 50vh;
  right: 2.25rem;
  z-index: 10;
  list-style: none;
  padding: 0;
  margin: 0;
  transform: translateY(-50%);
  font-size: .75rem;
  line-height: 2em;
}

@media screen and (max-width:1068px){
  .JumpNav {
    right: 1rem;
    transform: translate(50%, -50%);
  }
}

.JumpNav li {
}

.JumpNav a {
  display: block;
  position: relative;
  height: 2em;
  width: 2em;
  text-decoration: none;
  text-align: right;
  white-space: nowrap;
  color: $ci-blue;
}

.JumpNav a > span {
  position: absolute;
  right: 2em;
  top: 0;
  overflow: hidden;
  width: 0;
  opacity: 0;
  text-shadow: 0 0 1px rgba(255, 255, 255, .1);
  transition: .3s opacity;
}

.JumpNav a:hover > span {
  width: auto;
  opacity: 1;
  top: -5px;
  padding: 5px 10px;
  background-color: #FFF;
}


.JumpNav a:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 1.1em;
  width: 1.1em;
  content: '';
  border-radius: 50%;
  border: 2px solid $ci-blue;
  transition: .3s;
  box-sizing: border-box;
}

.JumpNav a:hover:after,
.JumpNav li.is-current a:after {
  background-color: $ci-blue;
}

@media all and (max-width: 767px) {
  .JumpNav {
    display: none;
  }
}
