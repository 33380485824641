@import "../../../../vars";

.DrawerToggle {
    width: 29px;
    height: 30px;
    border-radius: 2px;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 3px;
    cursor: pointer;
    margin-left: auto;
}

.DrawerToggle div {
    width: 90%;
    height: 2px;
    margin: 2px 0;
    background-color: $ci-green;
}

.DrawerToggle span.DrawerCloseIcon {
    display: none;
    color: $ci-green;
    @include close_icon();
}



.Toolbar.Open .DrawerToggle div {
    display: none;
}

.Toolbar.Open .DrawerToggle span.DrawerCloseIcon {
    display: block;
}

@media screen and (min-width: 768px){
    .Toolbar.Open .DrawerToggle div {
        display: none;
    }

    .Toolbar.Open .DrawerToggle span.DrawerCloseIcon {
        display: block;
    }
}

