@import "../../../vars";

.AccordionItem {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 0 1 60px;
  min-width: 110px;
  transition: all .3s ease;
  background: transparent no-repeat left center scroll;
  background-size: cover;
  overflow: hidden;
  z-index: 2;
}

.AccordionItem.Active {
  position: relative;
  flex: 0 0 calc(100% - 180px);
  min-width: 200px;
  display: flex;
  align-items: stretch;
}

.AccordionItem:not(.Active) {
  justify-content: center;
}

.AccordionItem.first {
    background-color: $ci-blue;
    background-image: url(../../../img/blue-bg_500.jpg);
    background-position: bottom center;
    z-index: 2;

    .bgVideo {
      display: none;
    }
}

.PageOpen .AccordionItem.first video {
  display: none;
}

.AccordionItem.second {
  background-color: $ci-green;
  background-image: url(../../../img/ginkgo_bg_500.jpg);
}

.AccordionItem.second .AccordionItemBody {
  background-color: rgba(0, 0, 0, .3);
}

@media screen and (min-width: 768px) {
  .AccordionItem.first {
    background-image: url(../../../img/blue-bg_1000.jpg);

    .bgVideo {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;

      video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
      }
    }
  }

  .AccordionItem.second {
    background-image: url(../../../img/ginkgo_bg_1000.jpg);

    &:not(.Active) {
      background-position-x: -100px;
    }
  }
}

@media screen and (min-width: 1000px) {
  .AccordionItem.first {
    background-image: url(../../../img/blue-bg_1500.jpg);
  }

  .AccordionItem.second {
    background-image: url(../../../img/ginkgo_bg_1500.jpg);
  }
}

@media screen and (min-width: 1500px) {
  .AccordionItem.first {
    background-image: url(../../../img/blue-bg_2000.jpg);
  }

  .AccordionItem.second {
    background-image: url(../../../img/ginkgo_bg_2000.jpg);
  }
}

.AccordionItem.third {
  background-color: $ci-green;
}

.AccordionItem.fourth {
  background-color: $ci-blue;
}

.App.PageOpen .AccordionItem:not(.Active) {
  flex: 0 1 0;
  min-width: 0;
}

.App .AccordionItem.Active .wrapper {
  zoom: 1;
  transition: all .3s ease;
}

.App.PageOpen .AccordionItem.Active {
  flex: 2 1 100%;
  min-width: 200px;

  .wrapper {
    zoom: .85;
  }
}

.AccordionItemLabel {
  /* https://codepen.io/facundocorradini/pen/LxJVNQ */
  display: block;
  text-align: left;
  padding: 0 2rem;
  line-height: 60px;
  opacity: 1;
  font-size: 1.25rem;
  white-space: nowrap;
  text-decoration: none;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  color: #FFF;
}

.AccordionItem.Active .AccordionItemLabel {
  opacity: 0;
  left: -50%;
  line-height: 1px;
}

@media screen and (min-width: 768px) {
  .AccordionItem {
    flex-direction: row;
    flex: 0 1 110px;
  }

  .AccordionItem.Active {
    flex: 0 1 calc(100% - 330px);
  }

  .AccordionItem.Active .AccordionItemLabel {
    display: none;
  }
  .AccordionItem:not(.Active) .AccordionItemLabel {
    height: 100vh;
    padding: 3rem 0;
    writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    transform: rotate(180deg);
    text-orientation: sideways;
    line-height: 110px;
  }

  .AccordionItemLinks a {
    font-size: 1rem;
  }
}

.AccordionItemHeader {
  padding: 0;
  margin: 0;
}

.AccordionItemBody {
  overflow-y: auto;
  position: relative;
  padding: 0;
  opacity: 0;
  transition: all .3s ease-in-out;
  display: flex;
  align-items: flex-start;
  height: 100%;
}


.AccordionItemBody .wrapper {
  padding: 0 2rem;
  max-height: 100%;
  overflow: auto;
}

@media screen and (min-height: 500px) {
  .AccordionItemBody {
    overflow: hidden;
    align-items: center;
  }
}

@media screen and (min-width: 768px) {
  .AccordionItemBody {
    left: 100px;
    max-width: 500px;
    width: calc(100vw - 416px);
    padding: 0;
  }

  .AccordionItemBody .wrapper {
    padding: 0 45px;
  }
}

.AccordionItem:not(.Active) .AccordionItemBody {
  opacity: 0;
}

.App.PageOpen .Active .AccordionItemBody {
  opacity: 0;
  height: 0;
  padding: 0;
}


.App.PageOpen .AccordionItemBody {
  width: calc(100vw - 50px);
}


.App.PageOpen .AccordionItemBody {

}

.AccordionItem.Active .AccordionItemBody {
  left: -1px;
  opacity: 1;
}

.AccordionItemLinks {
  margin: 1rem 0;
  padding: 0;
  list-style: none;
}

.AccordionItemLinks.list li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.AccordionItemLinks.list li:before {
  color: $accordion-item-link-color;
  content: '>';
  position: absolute;
  left: 0;
  top: 0;
  font-size: .875em;
  line-height: 1.75em;
}

.AccordionItemLinks a {
  font-size: 1.125rem;
  display: inline-block;
  color: $accordion-item-link-color;
}

.AccordionItemLinks.list a {
  display: inline;
}

.AccordionItemLinks.buttons a {
  display: block;
  text-align: center;
  padding: 0.625rem 1rem;
  line-height: 1.5rem;
  font-size: .75rem;
  text-decoration: none;
  color: $accordion-item-button-text-color;
  background-color: $accordion-item-button-color;
  margin-bottom: 1.5rem;
}

.AccordionItemBody {
  color: $accordion-item-body-color;
}

.AccordionItemText {
  font-size: .875rem;
  line-height: 1.25rem;
  color: $accordion-item-body-color;
}

.AccordionItemBody .AccordionItemHeader {
   margin: .75em 0;
   color: $accordion-item-header-color;
}

.second .AccordionItemBody .AccordionItemHeader {
  color: $ci-white;
}

.AccordionItemBody {
  a {
    color: $accordion-item-link-color;
  }

  .AccordionItemText a {
    color: $accordion-item-body-color;
    font-size: .875rem;
  }
}


.invert .AccordionItemLinks.list li:before {
  color: $accordion-item-link-color-inv;
}

.invert .AccordionItemLinks a {
  color: $accordion-item-link-color-inv;
}

.invert .AccordionItemLinks.buttons a {
  color: $accordion-item-button-text-color-inv;
  background-color: $accordion-item-button-color-inv;
}

.invert .AccordionItemBody {
  color: $accordion-item-body-color-inv;
}

.invert .AccordionItemText {
  color: $accordion-item-body-color-inv;
}

.invert .AccordionItemBody .AccordionItemHeader {
  color: $accordion-item-header-color-inv;
}

.invert .AccordionItemLinks a {
  color: $accordion-item-header-color-inv;
}
