@import "../../../vars";

.ClosePage {
  display: none;
  position: absolute;
  left: 31px;
  bottom: 31px;
  writing-mode: vertical-lr;
  text-orientation: sideways;
  -ms-writing-mode: tb-lr;
  text-align: left;
  height: auto;
  opacity: 1;
  padding: 30px 0;
  line-height: 20px;
  min-width: 20px;
  font-size: 1rem;
  white-space: nowrap;
  text-decoration: none;
  color: $ci-green;
  transform: rotate(180deg);
  transition: all .3s ease;
}

.ClosePage:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center scroll;
  background-size: 20px 20px;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Ctitle%3Eclose%3C/title%3E%3Cpath d='M3.636 2.224l14.142 14.142-1.414 1.414L2.222 3.638z' fill='%23BBC925'/%3E%3Cpath d='M17.776 3.636L3.634 17.778 2.22 16.364 16.362 2.222z' fill='%23BBC925'/%3E%3C/svg%3E");
}

.Toolbar.Open .ClosePage {
  opacity: 0;
  transform: translateX(-100px) rotate(180deg);
}


@media screen  and (min-width: 768px){
  .ClosePage {
    display: block;
  }

}
